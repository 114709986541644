export const myAnatomyHomePageUrl = 'https://myanatomy.ai';

export const openInNewWindowOptions = 'noopenner noreferrer';

export const supportEmailAddress = 'support@myanatomy.in';

export const frontendUrl = 'https://qa-interview.myanatomy.ai'; //http://localhost:3000
// export const frontendUrl = 'http://localhost:3002';

export const backendUrl = 'https://qa-interview-api.myanatomy.ai';
// export const backendUrl = 'http://localhost:8002';

export const fetchMeetingDetailsRetryTimeout = 5000;

export const toastIdForMeetingView = 'TOAST_CONTAINER_FOR_MEETING_DETAILS_NOT_FETCHED';

export const minimumMeetingPasswordLength = 5;

export const externalVideoDomain = 'video.myanatomy.in';  //'localhost:8080';
// export const externalVideoDomain = 'localhost:8080';  //'localhost:8080';

export const indicatorForInviteOnlyMeeting = 'INVITE_LINK';

export const indicatorForPublicMeeting = 'PUBLIC_LINK';

export const minimumMeetingTitleLength = 5;

export const meetingTitleErrorMessages = {
  default: 'Meeting title is required',
  length: `Minimum length of meeting title must be : ${minimumMeetingTitleLength}`,
};

export const meetingPasswordErrorMessages = {
  default: 'Meeting password is required',
  length: `Minimum length of meeting password must be : ${minimumMeetingPasswordLength}`,
  confirm: 'Password does not match',
};

export const emailErrorMessages = {
  default: 'Email is required',
  validation: 'Enter a valid email',
};

export const nameErrorMessages = {
  default: 'Name is required',
};

export const timeErrorMessages = {
  start: 'Meeting cannot start before current time',
  endBeforeStart: 'Meeting cannot end before current time',
  endBeforeCurrent: 'Meeting cannot end before current time',
};

export const meetingParticipantErrorMessages = {
  default: 'At least one participant is required',
};

export const meetingModeratorErrorMessages = {
  default: 'At least one moderator is required',
};

export const joinMeetingDisabledButtonTitle = 'Enter name and valid email to proceed';

export const pageSizes = {
  fetchAllMeeting: 5,
};

export const publicJoinUrl = '/join/<<meetingId>>';

export const defaultParticipantName = 'participant';

export const codeFrameCodeShareUrlForParticipants = 'https://qa-code-frame.myanatomy.ai/service/LiveCodeShare?meetingId=<<meetingId>>&&participantToken=<<meetingToken>>&&externalMeetingId=<<externalMeetingId>>&&participantName=<<participantName>>';

// export const codeFrameCodeShareUrlForModerators = 'http://localhost:3001/service/LiveCodeShare?meetingId=<<meetingId>>&&moderatorToken=<<meetingToken>>&&participantToken=<<participantToken>>&&externalMeetingId=<<externalMeetingId>>&&participantName=<<participantName>>';

export const codeFrameURL = 'https://qa-code-frame.myanatomy.ai';
export const codeFrameCodeShareUrlForModerators = 'https://qa-code-frame.myanatomy.ai/service/LiveCodeShare?meetingId=<<meetingId>>&&moderatorToken=<<meetingToken>>&&participantToken=<<participantToken>>&&externalMeetingId=<<externalMeetingId>>&&participantName=<<participantName>>';

// export const myMeetingFrontEndURL = 'http://localhost:3000/meet';

export const movieFrontEndURL = 'video.myanatomy.in';

// '/' is required below for codeframe URL.

export const codeFrameFrontEndUrl = 'https://qa-code-frame.myanatomy.ai/';
// export const codeFrameFrontEndUrl = 'http://localhost:3004/';
export const codeFrameBackEndUrl = 'https://qa-code-frame-api.myanatomy.ai/';
// export const codeFrameBackEndUrl = 'http://localhost:8004/';
