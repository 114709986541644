export const GET_MEETING_DETAILS_USING_MEETING_TOKEN = 'meeting/GET_MEETING_DETAILS_USING_MEETING_TOKEN';
export const GET_MEETING_DETAILS_USING_MEETING_TOKEN_PENDING = 'meeting/GET_MEETING_DETAILS_USING_MEETING_TOKEN_PENDING';
export const GET_MEETING_DETAILS_USING_MEETING_TOKEN_FULFILLED = 'meeting/GET_MEETING_DETAILS_USING_MEETING_TOKEN_FULFILLED';
export const GET_MEETING_DETAILS_USING_MEETING_TOKEN_REJECTED = 'meeting/GET_MEETING_DETAILS_USING_MEETING_TOKEN_REJECTED';

export const START_MEETING = 'meeting/START_MEETING';
export const START_MEETING_PENDING = 'meeting/START_MEETING_PENDING';
export const START_MEETING_FULFILLED = 'meeting/START_MEETING_FULFILLED';
export const START_MEETING_REJECTED = 'meeting/START_MEETING_REJECTED';

export const VERIFY_PASSWORD_AND_JOIN_MEETING = 'meeting/VERIFY_PASSWORD_AND_JOIN_MEETING';
export const VERIFY_PASSWORD_AND_JOIN_MEETING_PENDING = 'meeting/VERIFY_PASSWORD_AND_JOIN_MEETING_PENDING';
export const VERIFY_PASSWORD_AND_JOIN_MEETING_FULFILLED = 'meeting/VERIFY_PASSWORD_AND_JOIN_MEETING_FULFILLED';
export const VERIFY_PASSWORD_AND_JOIN_MEETING_REJECTED = 'meeting/VERIFY_PASSWORD_AND_JOIN_MEETING_REJECTED';

export const GET_MEETING_DETAILS_FOR_PUBLIC_LINK = 'meeting/GET_MEETING_DETAILS_FOR_PUBLIC_LINK';
export const GET_MEETING_DETAILS_FOR_PUBLIC_LINK_PENDING = 'meeting/GET_MEETING_DETAILS_FOR_PUBLIC_LINK_PENDING';
export const GET_MEETING_DETAILS_FOR_PUBLIC_LINK_FULFILLED = 'meeting/GET_MEETING_DETAILS_FOR_PUBLIC_LINK_FULFILLED';
export const GET_MEETING_DETAILS_FOR_PUBLIC_LINK_REJECTED = 'meeting/GET_MEETING_DETAILS_FOR_PUBLIC_LINK_REJECTED';

export const JOIN_MEETING_WITH_PUBLIC_LINK = 'meeting/JOIN_MEETING_WITH_PUBLIC_LINK';
export const JOIN_MEETING_WITH_PUBLIC_LINK_PENDING = 'meeting/JOIN_MEETING_WITH_PUBLIC_LINK_PENDING';
export const JOIN_MEETING_WITH_PUBLIC_LINK_FULFILLED = 'meeting/JOIN_MEETING_WITH_PUBLIC_LINK_FULFILLED';
export const JOIN_MEETING_WITH_PUBLIC_LINK_REJECTED = 'meeting/JOIN_MEETING_WITH_PUBLIC_LINK_REJECTED';

export const CREATE_NEW_MEETING = 'meeting/CREATE_NEW_MEETING';
export const CREATE_NEW_MEETING_PENDING = 'meeting/CREATE_NEW_MEETING_PENDING';
export const CREATE_NEW_MEETING_FULFILLED = 'meeting/CREATE_NEW_MEETING_FULFILLED';
export const CREATE_NEW_MEETING_REJECTED = 'meeting/CREATE_NEW_MEETING_REJECTED';

export const FETCH_ALL_MEETINGS = 'meeting/FETCH_ALL_MEETINGS';
export const FETCH_ALL_MEETINGS_PENDING = 'meeting/FETCH_ALL_MEETINGS_PENDING';
export const FETCH_ALL_MEETINGS_FULFILLED = 'meeting/FETCH_ALL_MEETINGS_FULFILLED';
export const FETCH_ALL_MEETINGS_REJECTED = 'meeting/FETCH_ALL_MEETINGS_REJECTED';

export const GET_MEETING_DETAILS_WITH_MEETING_ID = 'meeting/GET_MEETING_DETAILS_WITH_MEETING_ID';
export const GET_MEETING_DETAILS_WITH_MEETING_ID_PENDING = 'meeting/GET_MEETING_DETAILS_WITH_MEETING_ID_PENDING';
export const GET_MEETING_DETAILS_WITH_MEETING_ID_FULFILLED = 'meeting/GET_MEETING_DETAILS_WITH_MEETING_ID_FULFILLED';
export const GET_MEETING_DETAILS_WITH_MEETING_ID_REJECTED = 'meeting/GET_MEETING_DETAILS_WITH_MEETING_ID_REJECTED';

export const CLEAR_ALL_MEETING_DATA = 'meeting/CLEAR_ALL_MEETING_DATA';

export const GET_MEETING_SUBMISSIONS = 'meeting/GET_MEETING_SUBMISSIONS';
export const GET_MEETING_SUBMISSIONS_PENDING = 'meeting/GET_MEETING_SUBMISSIONS_PENDING';
export const GET_MEETING_SUBMISSIONS_FULFILLED = 'meeting/GET_MEETING_SUBMISSIONS_FULFILLED';
export const GET_MEETING_SUBMISSIONS_REJECTED = 'meeting/GET_MEETING_SUBMISSIONS_REJECTED';

export const FETCH_PARTICIPANTS_SHARING_BOARD = 'meeting/FETCH_PARTICIPANTS_SHARING_BOARD';
export const FETCH_PARTICIPANTS_SHARING_BOARD_PENDING = 'meeting/FETCH_PARTICIPANTS_SHARING_BOARD_PENDING';
export const FETCH_PARTICIPANTS_SHARING_BOARD_FULFILLED = 'meeting/FETCH_PARTICIPANTS_SHARING_BOARD_FULFILLED';
export const FETCH_PARTICIPANTS_SHARING_BOARD_REJECTED = 'meeting/FETCH_PARTICIPANTS_SHARING_BOARD_REJECTED';