import { backendUrl } from '../../configFiles/config';
import post from '../../utils/fetchRequests/post';
import {
  SET_MEETING_TOKEN,
  ADMIN_LOGIN,
} from './actionTypes';

export const setMeetingToken = (token) => (dispatch) => {
  dispatch({
    type: SET_MEETING_TOKEN,
    payload: {
      data: {
        token,
      },
    },
  });
};

export const adminLogin = (email, password, history) => (dispatch) => {
  dispatch({
    type: ADMIN_LOGIN,
    payload:
      post(
        `${backendUrl}/api/v1/admin/login`,
        {
          email,
          password,
        },
      ),
  })
    .then(({ action: { payload: { data } } }) => {
      if (!data) {
        window.location.assign('/');
      }

      const {
        token,
      } = data;

      if (!token) {
        window.location.assign('/');
      }

      history.push('/admin');
    })
    .catch(() => {
      window.location.assign('/');
    });
};
