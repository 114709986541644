import React from 'react';
import propTypes from 'prop-types';
import redirectToHome from '../../utils/redirects/home';

// import { Jutsu } from 'react-jutsu';
import MeetingNotStartedView from '../MeetingNotStartedView';
import MeetingStartedView from '../MeetingStartedView';
import { toast, ToastContainer } from 'react-toastify';
import { toastIdForMeetingView } from '../../configFiles/config';

class Meeting extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      password: undefined,
    };
  }

  componentDidMount () {
    const {
      token,
    } = this.props;
    
    if (!token) {
      const message = 'Could not load the meeting';
      redirectToHome(message, toastIdForMeetingView);
    }
  }

  render () {
    const {
      hasMeetingStarted,
      history,
      match,
    } = this.props;

    return (
      <div>
        {
          hasMeetingStarted ?
            <div>
              <MeetingStartedView
                history={history}
                match={match}
              />
            </div>
            :
            <MeetingNotStartedView
              history={history}
              match={match}
            />
        }
        <ToastContainer
          newestOnTop={true}
          enableMultiContainer={true}
          limit={5}
          position={toast.POSITION.BOTTOM_RIGHT}
          toastId={toastIdForMeetingView}
          hideProgressBar={true}
        />
      </div>
    );
  }
}

// const Meeting = () => {
//   const [room, setRoom] = useState('');
//   const [name, setName] = useState('');
//   const [call, setCall] = useState(false);

//   const handleClick = event => {
//     event.preventDefault();
//     if (room && name) setCall(true);
//   };

//   return call ? (
//     <Jutsu
//       domain={'video.myanatomy.in'}
//       roomName={room}
//       userName={name}
//       loadingComponent={<p>loading ...</p>} />
//   ) : (
//     <form>
//       <input id='room' type='text' placeholder='Room' value={room} onChange={(e) => setRoom(e.target.value)} />
//       <input id='name' type='text' placeholder='Name' value={name} onChange={(e) => setName(e.target.value)} />
//       <button onClick={handleClick} type='submit'>
//         Start / Join
//       </button>
//     </form>
//   );
// };

Meeting.propTypes = {
  hasMeetingStarted: propTypes.bool,
  token: propTypes.string,
  history: propTypes.object,
  match: propTypes.object,
};

Meeting.defaultProps = {
  hasMeetingStarted: false,
  token: undefined,
};

export default Meeting;
