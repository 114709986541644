import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import { Button } from '@material-ui/core';

import './adminActionsView.css';

const AdminActionsView = ({ history, adminToken }) => {
  useEffect(() => {
    if (!adminToken) {
      window.location.assign('/admin/login');
    }
  });
  return (
    <div className='adminActionsContainer'>
      <div className='adminActions'>
        <div className='adminActionsCreateMeetingContainer'>
          <Button
            id='adminActionsCreateMeetingButton'
            variant='contained'
            onClick={() => {
              history.push('/admin/meeting/create');
            }}
            className={'adminActionsCreateMeetingButton'}
          >
            CREATE NEW MEETING
          </Button>
        </div>
        <div className='adminActionsViewMeetingsContainer'>
          <Button
            id='adminActionsViewMeetingsButton'
            variant='contained'
            onClick={() => {
              history.push('/admin/meetings/view');
            }}
            className={'adminActionsViewMeetingsButton'}
          >
            VIEW MEETINGS
          </Button>
        </div>
      </div>
    </div>
  );
};

AdminActionsView.propTypes = {
  history: propTypes.object,
  adminToken: propTypes.string,
};

AdminActionsView.defaultProps = {
  history: {
    push: () => { },
  },
  adminToken: '',
};

export default AdminActionsView;
