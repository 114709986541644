import {
  getMeetingContentHistoryDetails,
  fetchParticipantsSharingBoard,
} from '../../redux/meeting/actions';

export const mapStateToProps = state => ({
  meetingContentHistory: state.meeting.meetingContentHistory,
  isLoadingContentHistory: state.meeting.isFetchingMeetingContentHistory,
  token: state.auth.token,
  meetingId: state.meeting.meetingId,
  participantsAccessingCodeShare: state.meeting.participantsAccessingCodeShare,
  hasMeetingStarted: state.meeting.hasMeetingStarted,
  hasMeetingEnded: state.meeting.hasMeetingEnded,
  isFetchingParticipantsSharingBoard: state.meeting.isFetchingParticipantsSharingBoard,
  externalMeetingId: state.meeting.connectingVideoId,
});

export const mapDispatchToProps = dispatch => ({
  getSubmissionsForMeeting: () => {
    dispatch(getMeetingContentHistoryDetails());
  },
  fetchParticipantsSharingBoard: (meetingId) => {
    dispatch(fetchParticipantsSharingBoard(meetingId));
  },
});
