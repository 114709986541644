import React from 'react';

import propTypes from 'prop-types';

import {
  Button,
  TextField,
  Checkbox,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import moment from 'moment';

import validator from 'validator';

import './adminCreateMeetingView.css';
import TopNavBar from '../TopNavBar';

import {
  indicatorForInviteOnlyMeeting,
  indicatorForPublicMeeting,
  meetingTitleErrorMessages,
  meetingPasswordErrorMessages,
  minimumMeetingTitleLength,
  minimumMeetingPasswordLength,
  timeErrorMessages,
  meetingParticipantErrorMessages,
  meetingModeratorErrorMessages,
} from '../../configFiles/config';
import { toast, ToastContainer } from 'react-toastify';
import { theme } from '../../utils/uiTheme';


class AdminCreateMeetingView extends React.Component {
  constructor (props) {
    super(props);


    const now = moment.now();

    const currentDateAndTime = moment(now + 300000).format('YYYY-MM-DDTHH:mm');

    const minimumEndTime = moment(now + 600000).format('YYYY-MM-DDTHH:mm');

    this.state = {
      meetingTitle: '',
      startTime: currentDateAndTime,
      startTimeErrorMessage: undefined,
      endTime: minimumEndTime,
      endTimeErrorMessage: undefined,
      enableModeratorControl: true,
      enableMeetingPassword: true,
      meetingPassword: '',
      confirmPassword: '',
      selectedMeetingType: indicatorForInviteOnlyMeeting,
      selectedModerators: [],
      selectedParticipants: [],
      moderators: '',
      participants: '',
      meetingTitleErrorMessage: meetingTitleErrorMessages.default,
      meetingPasswordErrorMessage: meetingPasswordErrorMessages.default,
      confirmMeetingPasswordErrorMessage: meetingPasswordErrorMessages.confirm,
      openPreviewDialog: false,
      participantsErrorMessage: meetingParticipantErrorMessages.default,
      moderatorsErrorMessage: meetingModeratorErrorMessages.default,
    };
  }

  componentDidMount () {
    const {
      adminToken,
    } = this.props;

    if (!adminToken) {
      window.location.assign('/admin/login');
    }
  }

  toggleModeratorControl = () => {
    const {
      enableModeratorControl,
    } = this.state;

    this.setState(() => ({
      enableModeratorControl: !enableModeratorControl,
    }));
  }

  toggleMeetingPassword = () => {
    const {
      enableMeetingPassword,
    } = this.state;
    this.setState(() => ({
      enableMeetingPassword: !enableMeetingPassword,
    }));
  }

  handleMeetingPasswordUpdate = (event) => {
    const updatedPassword = event.target.value;

    let meetingPassword = updatedPassword;
    let meetingPasswordErrorMessage;
    if (updatedPassword === '') {
      meetingPasswordErrorMessage = meetingPasswordErrorMessages.default;
    } else if (updatedPassword && updatedPassword.length < minimumMeetingPasswordLength) {
      meetingPasswordErrorMessage = meetingPasswordErrorMessages.length;
    }

    this.setState(() => ({
      meetingPassword,
      meetingPasswordErrorMessage,
    }));
  }

  handleConfirmMeetingPasswordUpdate = (event) => {
    const updatedConfirmPassword = event.target.value;

    const {
      meetingPassword,
    } = this.state;

    let confirmPassword = updatedConfirmPassword;

    let confirmMeetingPasswordErrorMessage;

    if (meetingPassword === '' || updatedConfirmPassword === '') {
      confirmMeetingPasswordErrorMessage = meetingPasswordErrorMessages.default;
    }

    if (confirmPassword !== meetingPassword) {
      confirmMeetingPasswordErrorMessage = meetingPasswordErrorMessages.confirm;
    }

    this.setState(() => ({
      confirmPassword,
      confirmMeetingPasswordErrorMessage,
    }));
  }

  changeMeetingType = (event) => {
    const newMeetingType = event.target.value;

    this.setState(() => ({
      selectedMeetingType: newMeetingType,
    }));
  }

  handleTitleUpdate = (event) => {
    const updatedMeetingTitle = event.target.value;

    let meetingTitle = updatedMeetingTitle;
    let meetingTitleErrorMessage;

    if (updatedMeetingTitle === '') {
      meetingTitleErrorMessage = meetingTitleErrorMessages.default;
    } else if (updatedMeetingTitle && updatedMeetingTitle.length < minimumMeetingTitleLength) {
      meetingTitleErrorMessage = meetingTitleErrorMessages.length;
    }


    this.setState(() => ({
      meetingTitle,
      meetingTitleErrorMessage,
    }));
  }

  handleStartTimeUpdate = (event) => {
    const startTime = event.target.value;

    const now = moment.now();

    let startTimeErrorMessage;

    const epochOfStartTime = moment(startTime).unix() * 1000;

    if (epochOfStartTime < now) {
      startTimeErrorMessage = timeErrorMessages.start;
    }

    this.setState(() => ({
      startTime,
      startTimeErrorMessage,
    }));
  }

  handleEndTimeUpdate = (event) => {
    const {
      startTime,
    } = this.state;

    const epochOfStartTime = moment(startTime).unix() * 1000;

    const endTime = event.target.value;

    const epcohOfEndTime = moment(endTime).unix() * 1000;

    let endTimeErrorMessage;

    if (epcohOfEndTime <= epochOfStartTime) {
      endTimeErrorMessage = timeErrorMessages.endBeforeStart;
    }

    const now = moment.now();

    if (epcohOfEndTime < now) {
      endTimeErrorMessage = timeErrorMessages.endBeforeCurrent;
    }

    this.setState(() => ({
      endTime,
      endTimeErrorMessage,
    }));
  }

  handleModeratorsUpdate = (event) => {
    const moderators = event.target.value;

    let moderatorsErrorMessage;

    let selectedModerators = [];

    if (!moderators || moderators === '') {
      moderatorsErrorMessage = meetingModeratorErrorMessages.default;
    } else {
      const validatedModeratorEmails = moderators.split('\n').filter(value => value !== '');

      const uniqueEmails = {};
      if (validatedModeratorEmails && validatedModeratorEmails.length > 0) {
        validatedModeratorEmails.forEach((email) => {
          if (validator.isEmail(email)) {
            uniqueEmails[email] = email;
          }
        });
        selectedModerators = Object.keys(uniqueEmails);
      }
    }

    if (selectedModerators.length === 0) {
      moderatorsErrorMessage = meetingModeratorErrorMessages.default;
    }

    this.setState(() => ({
      moderators,
      selectedModerators,
      moderatorsErrorMessage,
    }));
  }

  handleParticipantsUpdate = (event) => {
    const participants = event.target.value;

    let participantsErrorMessage;

    let selectedParticipants = [];

    if (!participants || participants === '') {
      participantsErrorMessage = meetingParticipantErrorMessages.default;
    } else {
      const validatedParticipantEmails = participants.split('\n').filter(value => value !== '');

      const uniqueEmails = {};
      if (validatedParticipantEmails && validatedParticipantEmails.length > 0) {
        validatedParticipantEmails.forEach((email) => {
          if (validator.isEmail(email)) {
            uniqueEmails[email] = email;
          }
        });
        selectedParticipants = Object.keys(uniqueEmails);
      }
    }

    if (selectedParticipants.length === 0) {
      participantsErrorMessage = meetingParticipantErrorMessages.default;
    }

    this.setState(() => ({
      participants,
      participantsErrorMessage,
      selectedParticipants,
    }));
  }

  createNewMeeting = () => {
    const {
      selectedModerators,
      selectedParticipants,
      enableModeratorControl,
      enableMeetingPassword,
      meetingPassword,
      startTime,
      endTime,
      meetingTitle,
      selectedMeetingType,
    } = this.state;

    const {
      create,
      history,
    } = this.props;

    const moderators = [];

    if (selectedModerators && selectedModerators.length > 0) {
      selectedModerators.forEach((moderatorEmail) => {
        const moderatorObject = {};
        const name = moderatorEmail.split('@')[0];

        moderatorObject.name = name;
        moderatorObject.email = moderatorEmail;
  
        moderators.push(moderatorObject);
      });
    }

    const participants = [];

    if (selectedParticipants && selectedParticipants.length > 0) {
      selectedParticipants.forEach((participantEmail) => {
        const participantObject = {};
        const name = participantEmail.split('@')[0];

        participantObject.name = name;
        participantObject.email = participantEmail;

        participants.push(participantObject);
      });
    }

    const meetingStartTime = moment(startTime).unix() * 1000;

    const meetingEndTime = moment(endTime).unix() * 1000;


    create(
      meetingStartTime,
      meetingEndTime,
      meetingTitle,
      enableMeetingPassword,
      meetingPassword,
      enableModeratorControl,
      moderators,
      selectedMeetingType,
      participants,
      history,
    );

    this.closeMeetingPreviewDialog();
  }

  initiateMeetingCreationRequest = () => {
    this.setState(() => ({
      openPreviewDialog: true,
    }));
  }

  closeMeetingPreviewDialog = () => {
    this.setState(() => ({
      openPreviewDialog: false,
    }));
  }

  render () {
    const {
      meetingTitleErrorMessage,
      enableModeratorControl,
      enableMeetingPassword,
      selectedMeetingType,
      meetingPasswordErrorMessage,
      confirmMeetingPasswordErrorMessage,
      selectedModerators,
      selectedParticipants,
      startTimeErrorMessage,
      endTimeErrorMessage,
      startTime,
      endTime,
      openPreviewDialog,
      meetingTitle,
      meetingPassword,
      participantsErrorMessage,
      moderatorsErrorMessage,
    } = this.state;

    return (
      <div className='adminCreateMeetingComponentContainer'>
        <div className='adminCreateMeetingTopNavBarContainer'>
          <TopNavBar />
        </div>
        <div className='adminCreateMeetingDetailsContainer'>
          <div className='adminCreateMeetingTitleContainer'>
            Create a new meeting
          </div>
          <div className='adminCreateMeetingInputContainer'>
            {/* <div className='adminCreateMeetingTitleContainer'>
              Create a new meeting
            </div> */}
            <div className='adminCreateMeetingTitleField'>
              <TextField
                id='adminMeetingTitleField'
                label='Meeting title'
                type='text'
                autoFocus
                placeholder='Meeting title'
                variant='outlined'
                required={true}
                helperText={meetingTitleErrorMessage}
                error={meetingTitleErrorMessage !== undefined}
                onChange={this.handleTitleUpdate}
                style={{
                  justifyContent: 'center',
                  display: 'flex',
                  width: '100%',
                  paddingTop: '5px',
                }}
              />
            </div>
            <div className='adminCreateMeetingStartTimeSelector'>
              <TextField
                id='datetime-local'
                label='Start time'
                fullWidth
                type='datetime-local'
                defaultValue={startTime}
                onChange={this.handleStartTimeUpdate}
                error={startTimeErrorMessage !== undefined}
                helperText={startTimeErrorMessage}
                // className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  // min: startTime - 1,
                }}
              />
            </div>
            <div className='adminCreateMeetingEndTimeSelector'>
              <TextField
                id='datetime-local'
                label='End time'
                fullWidth
                type='datetime-local'
                defaultValue={endTime}
                onChange={this.handleEndTimeUpdate}
                error={endTimeErrorMessage !== undefined}
                helperText={endTimeErrorMessage}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  // min: endTime - 1,
                }}
              />
            </div>
            <div className='adminCreateMeetingModeratorControlContainer'>
              <div className='adminCreateMeetingEnableModeratorControlContainer'>
                <div className='adminCreateMeetingEnableModeratorCheckbox'>
                  <Checkbox
                    checked={enableModeratorControl}
                    onChange={this.toggleModeratorControl}
                    color='primary'
                  />
                  <div className='adminCreateMeetingModeratorControlText'>
                    Enable moderator control?
                  </div>
                </div>
                <TextField
                  id='adminMeetingModeratorsField'
                  label='Moderators'
                  type='text'
                  multiline
                  rowsMax={3}
                  placeholder='abc@xyz.com'
                  variant={
                    enableModeratorControl ?
                      'outlined'
                      :
                      'filled'
                  }
                  required={true}
                  disabled={!enableModeratorControl}
                  helperText={
                    moderatorsErrorMessage && enableModeratorControl ?
                      moderatorsErrorMessage
                      :
                      null
                  }
                  error={moderatorsErrorMessage !== undefined && enableModeratorControl}
                  onChange={this.handleModeratorsUpdate}
                  style={{
                    justifyContent: 'center',
                    display: 'flex',
                    width: '100%',
                    paddingTop: '5px',
                  }}
                />
              </div>
            </div>
            <div className='adminCreateMeetingPasswordContainer'>
              <div className='adminCreateMeetingEnablePasswordContainer'>
                <Checkbox
                  checked={enableMeetingPassword}
                  onChange={this.toggleMeetingPassword}
                  color='primary'
                />
                <div className='adminCreateMeetingPasswordText'>
                  Enable meeting password ?
                </div>
              </div>
              <div
                className={
                  enableMeetingPassword ?
                    'adminCreateMeetingPasswordInputField'
                    :
                    'adminCreateMeetingDisabledMeetingPasswordInputField'
                }
                title={
                  enableMeetingPassword ?
                    ''
                    :
                    'Enable meeting password first using the checkbox'
                }
              >
                <TextField
                  id='adminMeetingPasswordField'
                  label='Meeting password'
                  // type='password'
                  placeholder='**********'
                  variant={
                    enableMeetingPassword ?
                      'outlined'
                      :
                      'filled'
                  }
                  required={true}
                  disabled={!enableMeetingPassword}
                  error={meetingPasswordErrorMessage !== undefined && enableMeetingPassword}
                  helperText={
                    (meetingPasswordErrorMessage && enableMeetingPassword) ?
                      meetingPasswordErrorMessage
                      :
                      null
                  }
                  onChange={this.handleMeetingPasswordUpdate}
                  style={{
                    justifyContent: 'center',
                    display: 'flex',
                    width: '100%',
                    paddingTop: '5px',
                  }}
                  inputProps={{
                    type: 'password',
                    autoComplete: 'new-password',
                  }}
                />
                <TextField
                  id='adminMeetingConfirmPasswordField'
                  label='Confirm Meeting password'
                  // type='password'
                  placeholder='**********'
                  variant={
                    enableMeetingPassword ?
                      'outlined'
                      :
                      'filled'
                  }
                  required={true}
                  disabled={!enableMeetingPassword}
                  error={confirmMeetingPasswordErrorMessage !== undefined && enableMeetingPassword}
                  helperText={
                    confirmMeetingPasswordErrorMessage && enableMeetingPassword ?
                      confirmMeetingPasswordErrorMessage
                      :
                      null
                  }
                  onChange={this.handleConfirmMeetingPasswordUpdate}
                  style={{
                    justifyContent: 'center',
                    display: 'flex',
                    width: '100%',
                    paddingTop: '5px',
                  }}
                  inputProps={{
                    type: 'password',
                    autoComplete: 'new-password',
                  }}
                />
              </div>
            </div>
            <div className='adminCreateMeetingInviteTypeContainer'>
              <FormControl
                component='div'
                fullWidth
              >
                <FormLabel component='div'>
                  Meeting type
                </FormLabel>
                <RadioGroup
                  value={selectedMeetingType}
                  onChange={this.changeMeetingType}
                  row
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <FormControlLabel
                    value={indicatorForInviteOnlyMeeting}
                    control={<Radio color='primary' />}
                    label='Invite'
                  />
                  <FormControlLabel
                    value={indicatorForPublicMeeting}
                    control={<Radio color='primary' />}
                    label='Public link'
                  />
                </RadioGroup>
              </FormControl>
              <TextField
                id='adminMeetingParticipantsField'
                label='Participants to invite'
                type='text'
                multiline
                rowsMax={5}
                placeholder='abc@xyz.com'
                variant={
                  selectedMeetingType === indicatorForInviteOnlyMeeting ?
                    'outlined'
                    :
                    'filled'
                }
                required={true}
                disabled={selectedMeetingType !== indicatorForInviteOnlyMeeting}
                helperText={
                  participantsErrorMessage && (selectedMeetingType === indicatorForInviteOnlyMeeting) ?
                    participantsErrorMessage
                    :
                    null
                }
                error={selectedParticipants && selectedParticipants.length === 0 && (selectedMeetingType === indicatorForInviteOnlyMeeting)}
                onChange={this.handleParticipantsUpdate}
                style={{
                  justifyContent: 'center',
                  display: 'flex',
                  width: '100%',
                  paddingTop: '5px',
                }}
              />
            </div>
          </div>
          <div
            className='adminCreateMeetingButtonContainer'
            style={
              (meetingTitleErrorMessage !== undefined ||
              (enableModeratorControl && selectedModerators && selectedModerators.length === 0) ||
              (meetingPasswordErrorMessage !== undefined && enableMeetingPassword) ||
              (confirmMeetingPasswordErrorMessage && enableMeetingPassword) ||
              (selectedMeetingType === indicatorForInviteOnlyMeeting && selectedParticipants && selectedParticipants.length === 0) ||
              (startTimeErrorMessage !== undefined) ||
              (endTimeErrorMessage !== undefined)) ?
                {
                  cursor: 'not-allowed',
                }
                :
                {
                  
                }
            }
            title={
              (meetingTitleErrorMessage !== undefined ||
              (enableModeratorControl && selectedModerators && selectedModerators.length === 0) ||
              (meetingPasswordErrorMessage !== undefined && enableMeetingPassword) ||
              (confirmMeetingPasswordErrorMessage && enableMeetingPassword) ||
              (selectedMeetingType === indicatorForInviteOnlyMeeting && selectedParticipants && selectedParticipants.length === 0) ||
              (startTimeErrorMessage !== undefined) ||
              (endTimeErrorMessage !== undefined)) ?
                'Complete the required details to proceed'
                :
                ''
            }
          >
            <Button
              id='adminCreateMeetingButton'
              variant='contained'
              onClick={this.initiateMeetingCreationRequest}
              className={
                meetingTitleErrorMessage !== undefined ||
                (enableModeratorControl && selectedModerators && selectedModerators.length === 0) ||
                (meetingPasswordErrorMessage !== undefined && enableMeetingPassword) ||
                (confirmMeetingPasswordErrorMessage && enableMeetingPassword) ||
                (selectedMeetingType === indicatorForInviteOnlyMeeting && selectedParticipants && selectedParticipants.length === 0) ||
                (startTimeErrorMessage !== undefined) ||
                (endTimeErrorMessage !== undefined) ?
                  'adminCreateMeetingButtonDisable'
                  :
                  'adminCreateMeetingButton'
              }
              title={'Click here to login'}
              disabled={
                meetingTitleErrorMessage !== undefined ||
                (enableModeratorControl && selectedModerators && selectedModerators.length === 0) ||
                (meetingPasswordErrorMessage !== undefined && enableMeetingPassword) ||
                (confirmMeetingPasswordErrorMessage && enableMeetingPassword) ||
                (selectedMeetingType === indicatorForInviteOnlyMeeting && selectedParticipants && selectedParticipants.length === 0) ||
                (startTimeErrorMessage !== undefined) ||
                (endTimeErrorMessage !== undefined)
              }
            >
              CREATE
            </Button>
          </div>
        </div>


        <Dialog
          open={openPreviewDialog}
          onClose={this.closeMeetingPreviewDialog}
          fullWidth
        >
          <DialogTitle
            id="alert-dialog-title"
            className="headerCreateMeetingPreview"
            style={{
              justifyContent: 'center',
              display: 'flex',
              color: 'white',
              backgroundColor: theme.primary1Color,
            }}
          >
            Meeting preview
          </DialogTitle>
          <DialogContent>
            <div className='meetingTitlePreview'>
              {meetingTitle}
            </div>
            <div className='meetingStartTimePreview'>
              Starts at : {startTime}
            </div>
            <div className='meetingEndTimePreview'>
              Ends at : {endTime}
            </div>
            {
              enableMeetingPassword ?
                <div className='meetingPasswordPreview'>
                  Password : {meetingPassword}
                </div>
                :
                null
            }
            <div className='meetingModeratorPreview'>
              {
                enableModeratorControl ?
                  <div className='meetingModeratorsPreview'>
                    Moderators :
                    <div>
                      <Table>
                        <TableBody style={{ border: '1px solid black' }}>
                          {
                            selectedModerators.map((moderatorEmail) => (
                              <TableRow
                                key={moderatorEmail}
                              >
                                <TableCell>
                                  {moderatorEmail}
                                </TableCell>
                              </TableRow>
                            ))
                          }
                        </TableBody>
                      </Table>
                    </div>
                  </div>
                  :
                  null
              }
            </div>
            <div className='meetingTypePreview'>
              {
                selectedMeetingType === indicatorForInviteOnlyMeeting ?
                  <div className='meetingParticipantsPreview'>
                    Participants :
                    <div>
                      <Table>
                        <TableBody style={{ border: '1px solid black' }}>
                          {
                            selectedParticipants.map((participantEmail) => (
                              <TableRow
                                key={participantEmail}
                              >
                                <TableCell>
                                  {participantEmail}
                                </TableCell>
                              </TableRow>
                            ))
                          }
                        </TableBody>
                      </Table>
                    </div>
                  </div>
                  :
                  null
              }
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              color='primary'
              className=''
              onClick={this.createNewMeeting}
            >
              Create
            </Button>
            <Button
              onClick={this.closeMeetingPreviewDialog}
              color='primary'
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <ToastContainer
          newestOnTop={true}
          enableMultiContainer={true}
          limit={5}
          position={toast.POSITION.BOTTOM_RIGHT}
          toastId='MEETING_CREATE_TOAST_CONTAINER'
          hideProgressBar={true}
        />
      </div>
    );
  }
}

AdminCreateMeetingView.propTypes = {
  adminCreateNewMeeting: propTypes.func,
  adminToken: propTypes.string,
  history: propTypes.object,
  create: propTypes.func,
};

AdminCreateMeetingView.defaultProps = {
  adminCreateNewMeeting: () => { },
  adminToken: '',
  create: () => { },
};

export default AdminCreateMeetingView;
