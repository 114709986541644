import { getPublicLinkJoiningDetails, joinMeetingWithPublicLink } from '../../redux/meeting/actions';

export const mapStateToProps = state => ({
  title: state.meeting.title,
});

export const mapDispatchToProps = dispatch => ({
  getDetails: (meetingId) => {
    dispatch(getPublicLinkJoiningDetails(meetingId));
  },
  joinMeeting: (meetingId, name, email, history) => {
    dispatch(joinMeetingWithPublicLink(meetingId, name, email, history));
  },
});
