import {
  SET_MEETING_TOKEN,
  ADMIN_LOGIN,
  ADMIN_LOGIN_PENDING,
  ADMIN_LOGIN_FULFILLED,
  ADMIN_LOGIN_REJECTED,
} from './actionTypes';

const defaultState = {
  token: undefined,
  isAdminLoginInProgess: false,
  adminToken: undefined,
};

const auth = (state = defaultState, action) => {
  switch (action.type) {
  case SET_MEETING_TOKEN: {
    const { data } = action.payload;
    if (!data) {
      return {
        ...state,
      };
    }
    const {
      token,
    } = data;
    
    return {
      ...state,
      token,
    };
  }

  case ADMIN_LOGIN: {
    return {
      ...state,
      isAdminLoginInProgess: true,
    };
  }

  case ADMIN_LOGIN_PENDING: {
    return {
      ...state,
      isAdminLoginInProgess: true,
    };
  }

  case ADMIN_LOGIN_FULFILLED: {
    const {
      data,
    } = action.payload;

    if (!data) {
      return {
        ...state,
        isAdminLoginInProgess: false,
      };
    }

    const {
      token,
    } = data;

    if (!token) {
      return {
        ...state,
        isAdminLoginInProgess: false,
      };
    }

    return {
      ...state,
      isAdminLoginInProgess: false,
      adminToken: token,
    };
  }

  case ADMIN_LOGIN_REJECTED: {
    return {
      ...state,
      isAdminLoginInProgess: false,
    };
  }

  default:
    return state;
  }
};

export default auth;
