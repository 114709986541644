import React from 'react';
import propTypes from 'prop-types';
import { Button } from '@material-ui/core';

import './meetingNotStartedView.css';

import TopNavBar from '../TopNavBar';

class MeetingNotStartedView extends React.Component {
  constructor (props) {
    super(props);
    this.state = {

    };
  }



  startMeeting = () => {
    const {
      startNewMeeting,
      isModerator,
    } = this.props;

    if (isModerator) {
      startNewMeeting();
    }
  }

  render () {
    const {
      isModerator,
    } = this.props;

    return (
      <div className='meetingNotStartedView'>
        <TopNavBar />
        <div className='meetingNotStartedViewContainer'>
          <div className='meetingNotStartedText'>
            This meeting has not yet started.&nbsp;
            {
              !isModerator ?
                <div>
                  We&apos;ll let the moderator know you&apos;re here.
                </div>
                :
                null
            }
          </div>
          {
            isModerator ?
              <div className='startMeetingButtonContainer'>
                <Button
                  id='startMeetingButton'
                  variant='contained'
                  onClick={this.startMeeting}
                  className='startMeetingButton'
                  title={'Click here to start the meeting'}
                >
                  Start Meeting
                </Button>
              </div>
              :
              null
          }
        </div>
      </div>
    );
  }
}

MeetingNotStartedView.propTypes = {
  isModerator: propTypes.bool,
  startNewMeeting: propTypes.func,
};

MeetingNotStartedView.defaultProps = {
  isModerator: false,
  startNewMeeting: () => { },
};

export default MeetingNotStartedView;
