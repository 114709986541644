import {
  startMeeting,
} from '../../redux/meeting/actions';

export const mapStateToProps = state => ({
  isModerator: state.meeting.isModerator,
});

export const mapDispatchToProps = dispatch => ({
  startNewMeeting: () => {
    dispatch(startMeeting());
  },
});
