import fetch from 'isomorphic-fetch';

export default async (endPoint, data, headers = {}, isFormData = false) => {
  const response = await fetch(endPoint, {
    method: 'POST',
    headers: {
      ...(!isFormData && { 'Content-Type': 'application/json' }),
      ...headers,
    },
    body: isFormData ? data : JSON.stringify(data),
  });
  const responseJSON = await response.json();
  return new Promise((resolve, reject) => {
    try {
      const responseData = responseJSON;
      // Check if there is error in the responseData
      const { success } = responseData;
      if (success) {
        resolve(responseData);
      }
      else {
        const { errors } = responseData;
        reject({ errors });
      }
    } catch (ex) {
      reject();
    }
  });
};
