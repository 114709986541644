import { connect } from 'react-redux';

import {
  mapStateToProps,
  mapDispatchToProps,
} from './props';

import MeetingNotStartedView from './MeetingNotStartedView';

export default connect(mapStateToProps, mapDispatchToProps)(MeetingNotStartedView);
