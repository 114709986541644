import {
  GET_MEETING_DETAILS_USING_MEETING_TOKEN,
  START_MEETING,
  VERIFY_PASSWORD_AND_JOIN_MEETING,
  GET_MEETING_DETAILS_FOR_PUBLIC_LINK,
  JOIN_MEETING_WITH_PUBLIC_LINK,
  CREATE_NEW_MEETING,
  FETCH_ALL_MEETINGS,
  GET_MEETING_DETAILS_WITH_MEETING_ID,
  CLEAR_ALL_MEETING_DATA,
  GET_MEETING_SUBMISSIONS,
  FETCH_PARTICIPANTS_SHARING_BOARD,
} from './actionTypes';

import {
  backendUrl,
  fetchMeetingDetailsRetryTimeout,
  indicatorForInviteOnlyMeeting,
  pageSizes,
} from '../../configFiles/config';

import get from '../../utils/fetchRequests/get';
import post from '../../utils/fetchRequests/post';

import { showErrorToast } from '../../utils/toasts';
import { setMeetingToken } from '../auth/actions';

import redirectToHome from '../../utils/redirects/home';

export const getMeetingDetailsUsingMeetingToken = (meetingToken, history) => (dispatch) => {
  dispatch({
    type: GET_MEETING_DETAILS_USING_MEETING_TOKEN,
    payload:
      get(
        `${backendUrl}/api/v1/meeting/details?meetingToken=${meetingToken}`,
      ),
  })
    .then(({ action: { payload: { data } } }) => {
      if (!data) {
        showErrorToast('Meeting could not be started. Contact support.');
        history.push('/');
      }

      const {
        meetingDetails,
      } = data;

      if (!meetingDetails) {
        showErrorToast('Meeting could not be started. Contact support.');
        history.push('/');
      }

      dispatch(setMeetingToken(meetingToken));

      const {
        meetingId,
      } = meetingDetails;

      history.push(`/meeting/${meetingId}`);

      const {
        hasMeetingStarted,
        hasMeetingEnded,
        isModerator,
      } = meetingDetails;

      if (hasMeetingEnded) {
        showErrorToast('Meeting ended. Contact support.');
        history.push('/');
      }

      if (!hasMeetingStarted && !isModerator) {
        setTimeout(() => {
          dispatch(getMeetingDetailsUsingMeetingToken(meetingToken, history));
        }, fetchMeetingDetailsRetryTimeout);
      }
    })
    .catch(() => {
      history.push('/');
    });
};

export const startMeeting = () => (dispatch, getState) => {
  const { meetingId } = getState().meeting;
  const { token } = getState().auth;

  dispatch({
    type: START_MEETING,
    payload:
      post(
        `${backendUrl}/api/v1/meeting/start`,
        {
          meetingId,
        },
        {
          authorization: token,
        },
      ),
  });
};

export const verifyMeetingPassword = (password) => (dispatch, getState) => {
  const {
    token,
  } = getState().auth;

  const {
    meetingId,
  } = getState().meeting;

  dispatch({
    type: VERIFY_PASSWORD_AND_JOIN_MEETING,
    payload:
      post(
        `${backendUrl}/api/v1/meeting/password/verify`,
        {
          meetingId,
          password,
        },
        {
          authorization: token,
        },
      ),
  })
    .then(({ action: { payload: { data } } }) => {
      if (!data) {
        redirectToHome('Incorrect password');
      }
    })
    .catch(() => {
      // showErrorToast('Incorrect password entered');
      redirectToHome('Incorrect password');
    });
};

export const checkIfMeetingStartedForParticipant = () => (dispatch, getState) => {
  const {
    token,
  } = getState().auth;
  dispatch({
    type: GET_MEETING_DETAILS_USING_MEETING_TOKEN,
    payload:
      get(
        `${backendUrl}/api/v1/meeting/details?meetingToken=${token}`,
      ),
  });
};

export const getPublicLinkJoiningDetails = (meetingId) => (dispatch) => {
  dispatch({
    type: GET_MEETING_DETAILS_FOR_PUBLIC_LINK,
    payload:
      get(
        `${backendUrl}/api/v1/meeting/public/detils?meetingId=${meetingId}`,
      ),
  })
    .catch(() => {
      window.location.assign('/');
    });
};

export const joinMeetingWithPublicLink = (meetingId, name, email, history) => (dispatch) => {
  dispatch({
    type: JOIN_MEETING_WITH_PUBLIC_LINK,
    payload:
      post(
        `${backendUrl}/api/v1/meeting/participant/update/${meetingId}`,
        {
          name,
          email,
        },
      ),
  })
    .then(({ action: { payload: { data } } }) => {
      if (!data) {
        showErrorToast('Meeting could not be started. Contact support.');
        history.push('/');
      }

      const {
        meetingToken,
        meetingDetails,
      } = data;

      if (!meetingDetails || !meetingToken) {
        showErrorToast('Meeting could not be started. Contact support.');
        history.push('/');
      }

      dispatch(setMeetingToken(meetingToken));

      const {
        meetingId,
        hasMeetingStarted,
      } = meetingDetails;

      history.push(`/meeting/${meetingId}`);

      if (!hasMeetingStarted) {
        setTimeout(() => {
          dispatch(joinMeetingWithPublicLink(meetingId, name, email, history));
        }, fetchMeetingDetailsRetryTimeout);
      }

      const {
        hasMeetingEnded,
      } = meetingDetails;

      if (hasMeetingEnded) {
        showErrorToast('Meeting ended. Contact support.');
        history.push('/');
      }
    })
    .catch(() => {
      history.push('/');
    });
};

export const createNewMeeting = (
  startTime,
  endTime,
  title,
  enableMeetingPassword,
  meetingPassword,
  enableModeratorControl,
  selectedModerators,
  selectedMeetingType,
  selectedParticipants,
  history,
) => (dispatch, getState) => {
  const {
    adminToken,
  } = getState().auth;


  dispatch({
    type: CREATE_NEW_MEETING,
    payload:
      post(
        `${backendUrl}/api/v1/admin/meeting/create`,
        {
          startTime,
          endTime,
          title,
          isPasswordAccessEnabled: enableMeetingPassword,
          password: enableMeetingPassword ? meetingPassword : undefined,
          isModeratorControlEnabled: enableModeratorControl,
          moderators: enableModeratorControl ? selectedModerators : undefined,
          participants: selectedMeetingType === indicatorForInviteOnlyMeeting ? selectedParticipants : undefined,
        },
        {
          authorization: adminToken,
        },
      ),
  })
    .then(({ action: { payload: { data } } }) => {
      if (!data) {
        showErrorToast('Could not complete your request. Contact support');
      }

      // showSuccessToast('Meeting has been successfully created!');
      // toast.success('Meeting created');
      history.push('/admin');
    });
};

export const getAllMeetings = (pageNumber = 0, pageSize = pageSizes.fetchAllMeeting) => (dispatch, getState) => {
  const {
    adminToken,
  } = getState().auth;
  dispatch({
    type: FETCH_ALL_MEETINGS,
    payload:
      get(
        `${backendUrl}/api/v1/admin/meetings/all?pageNumber=${pageNumber}&pageSize=${pageSize}`,
        {
          authorization: adminToken,
        },
      ),
  });
};

export const getMeetingDetails = (meetingId) => (dispatch, getState) => {
  const {
    adminToken,
  } = getState().auth;
  
  dispatch({
    type: GET_MEETING_DETAILS_WITH_MEETING_ID,
    payload:
      get(
        `${backendUrl}/api/v1/admin/meeting/view/${meetingId}`,
        {
          authorization: adminToken,
        }
      ),
  });
};

export const clearAllMeetingDetails = () => (dispatch) => {
  dispatch({
    type: CLEAR_ALL_MEETING_DATA,
  });
};

export const getMeetingContentHistoryDetails = () => (dispatch, getState) => {
  const { token } = getState().auth;
  dispatch({
    type: GET_MEETING_SUBMISSIONS,
    payload:
      get(
        `${backendUrl}/api/v1/meeting/submissions/fetch`,
        {
          authorization: token,
        },
      ),
  });
};

export const fetchParticipantsSharingBoard = (meetingId) => (dispatch, getState) => {
  const { token } = getState().auth;
  dispatch({
    type: FETCH_PARTICIPANTS_SHARING_BOARD,
    payload:
      get(
        `${backendUrl}/api/v1/meeting/fetch/candidatesSharingBoard/${meetingId}`,
        {
          authorization: token,
        },
      ),
  });
};
