export const mapStateToProps = state => ({
  token: state.auth.token,
  meetingId: state.meeting.meetingId,
  hasMeetingStarted: state.meeting.hasMeetingStarted,
  hasMeetingEnded: state.meeting.hasMeetingEnded,
  externalMeetingId: state.meeting.connectingVideoId,
  name: state.meeting.name,
  isPasswordAccessEnabled: state.meeting.isPasswordAccessEnabled,
  isPasswordVerified: state.meeting.isPasswordVerified,
});

export const mapDispatchToProps = () => ({
});
