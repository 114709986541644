import React from 'react';

import propTypes from 'prop-types';

import { Jutsu } from 'react-jutsu';

import './externalVideoComponent.css';

import redirectToHome from '../../utils/redirects/home';
import Loader from '../Loader';
import {
  frontendUrl,
  externalVideoDomain,
  publicJoinUrl,
  // myMeetingFrontEndURL,
  movieFrontEndURL,
  myAnatomyHomePageUrl,
  codeFrameFrontEndUrl,
  codeFrameBackEndUrl,
} from '../../configFiles/config';

import ModeratorTopNavBar from '../ModeratorTopNavBar';
import TopNavBar from '../TopNavBar';
import { toolbarOptions } from './constant';


class ExternalVideoComponent extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      showDummyLoader: true,
    };
  }

  componentDidMount () {
    const {
      token,
      connectingVideoId,
    } = this.props;

    if (!token || !connectingVideoId) {
      redirectToHome('Cannot start meeting');
    }

    setTimeout(() => {
      this.setState(() => ({
        showDummyLoader: false,
      }));
    }, 3000);
  }

  handleMeetingEnd () {
    window.location.href = myAnatomyHomePageUrl;
  }

  render () {
    const {
      connectingVideoId,
      name,
      meetingId,
      isModerator,
      // token,
      useMyMeetingMicroService,
    } = this.props;

    const {
      showDummyLoader,
    } = this.state;

    let joinUrl = publicJoinUrl;

    if (joinUrl) {
      joinUrl = joinUrl.replace('<<meetingId>>', meetingId);
    }
    const domainForMovie = useMyMeetingMicroService ? movieFrontEndURL : externalVideoDomain;
    return (
      <div className='externalVideoComponent'>
        <div className='externalVideoComponentTopNavBarContainer'>
          {
            isModerator ?
              <ModeratorTopNavBar />
              :
              <TopNavBar />
          }
        </div>
        
        {
          // useMyMeetingMicroService ?
          //   <iframe
          //     // allow={`camera ${myMeetingFrontEndURL}; microphone ${myMeetingFrontEndURL}`}
          //     allow={`camera *; microphone *; display-capture; clipboard-write`}
          //     src={`${myMeetingFrontEndURL}/meeting/join?meetingId=${meetingId}&meetingToken=${token}`}
          //     className='myMeetingContainer'
          //   />
          //   :
          <Jutsu
            domain={domainForMovie}
            roomName={connectingVideoId}
            userName={name}
            displayName={name}
            loadingComponent={<Loader />}
            containerStyles={{
              width: '100vw',
              height: '93vh',
            }}
            onMeetingEnd={this.handleMeetingEnd}
            configOverwrite={{
              prejoinPageEnabled: false,
              isModerator: isModerator,
              interviewJoinUrl: `${frontendUrl}${joinUrl}`,
              meetingId: meetingId,
              MYANATOMY_URL: myAnatomyHomePageUrl,
              INTERVIEW_URL: frontendUrl,
              CODE_FRAME_FRONTEND_URL: codeFrameFrontEndUrl,
              CODE_FRAME_BACKEND_URL: codeFrameBackEndUrl,
              localRecording: {
                disable: isModerator ? false : true,
                notifyAllParticipants: true,
              },
              toolbarButtons: toolbarOptions,
              startWithVideoMuted: true,
            }}
          />
        }
        {
          showDummyLoader ?
            <Loader />
            :
            null
        }
      </div>
    );
  }
}

ExternalVideoComponent.propTypes = {
  token: propTypes.string,
  connectingVideoId: propTypes.string,
  name: propTypes.string,
  email: propTypes.string,
  meetingId: propTypes.string,
  isModerator: propTypes.bool,
  useMyMeetingMicroService: propTypes.bool,
};

ExternalVideoComponent.defaultProps = {
  token: '',
  connectingVideoId: '',
  name: '',
  email: '',
  meetingId: '',
  isModerator: false,
  useMyMeetingMicroService: false,
};

export default ExternalVideoComponent;
