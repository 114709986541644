import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import HomePageView from './components/HomePageView';
import MeetingView from './components/MeetingView';
import StartMeetingWithToken from './components/StartMeetingWithToken';

import AdminLoginComponent from './components/AdminLoginComponent';
import AdminCreateMeetingView from './components/AdminCreateMeetingView';

import JoinMeetingView from './components/JoinMeetingView';

import AdminActionsView from './components/AdminActionsView';
import AdminViewAllMeetingsView from './components/AdminViewAllMeetingsView';
import AdminSpecificMeetingView from './components/AdminSpecificMeetingView';

import PageNotFoundView from './views/PageNotFound';
import LeftMeetingHomePageView from './components/LeftMeetingHomePageView';


const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route
        exact
        path='/admin'
        render= {({ history }) => (
          <AdminActionsView
            history={history}
          />
        )}
      />
      <Route
        exact
        path='/admin/meeting/create'
        render= {({ history, match }) => (
          <AdminCreateMeetingView
            history={history}
            match={match}
          />
        )}
      />

      <Route
        exact
        path='/admin/meetings/view'
        render= {({ history, match }) => (
          <AdminViewAllMeetingsView
            history={history}
            match={match}
          />
        )}
      />

      <Route
        exact
        path='/admin/meeting/view/:meetingId'
        render= {({ history, match }) => (
          <AdminSpecificMeetingView
            history={history}
            match={match}
          />
        )}
      />

      <Route
        exact
        path='/admin/login'
        render= {({ history, match }) => (
          <AdminLoginComponent
            history={history}
            match={match}
          />
        )}
      />

      <Route
        exact
        path='/start'
        render= {({ history, match }) => (
          <StartMeetingWithToken
            history={history}
            match={match}
          />
        )}
      />

      <Route
        exact
        path='/join/:meetingId'
        render= {({ history, match }) => (
          <JoinMeetingView
            history={history}
            match={match}
          />
        )}
      />

      <Route
        exact
        path='/meeting/:meetingId'
        render= {({ history, match }) => (
          <MeetingView
            history={history}
            match={match}
          />
        )}
      />

      <Route
        exact
        path='/left/meeting'
        render= {() => (
          <LeftMeetingHomePageView />
        )}
      />

      <Route
        exact
        path='/'
        render= {() => (
          <HomePageView />
        )}
      />

      <Route
        path='/*'
        render = {() => (
          <PageNotFoundView />
        )}
      />
    </Switch>
  </BrowserRouter>
);

export default Routes;
