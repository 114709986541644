import { connect } from 'react-redux';

import {
  mapStateToProps,
  mapDispatchToProps,
} from './props';

import AdminCreateMeetingView from './AdminCreateMeetingView';

export default connect(mapStateToProps, mapDispatchToProps)(AdminCreateMeetingView);
