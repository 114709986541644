import { connect } from 'react-redux';

import StartMeetingWithToken from './StartMeetingWithToken';

import {
  mapStateToProps,
  mapDispatchToProps,
} from './props';

export default connect(mapStateToProps, mapDispatchToProps)(StartMeetingWithToken);
