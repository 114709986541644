import React from 'react';

import propTypes from 'prop-types';
import TopNavBar from '../TopNavBar';
import moment from 'moment';

import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';


import './adminSpecificMeetingView.css';
import { defaultParticipantName } from '../../configFiles/config';
// import { CREATE_NEW_MEETING_FULFILLED } from '../../redux/meeting/actionTypes';

class AdminSpecificMeetingView extends React.Component {
  constructor (props) {
    super(props);

    this.state = {

    };
  }

  componentDidMount () {
    const {
      adminToken,
      getMeetingDetailsForSelectedMeeting,
      match,
    } = this.props;

    if (!adminToken) {
      window.location.assign('/admin/login');
    }

    const meetingId = match && match.params && match.params.meetingId;

    if (!meetingId) {
      window.location.assign('/admin/meetings/view');
    }

    getMeetingDetailsForSelectedMeeting(meetingId);
  }

  downloadMeetingContentHistory = () => {
    const {
      meetingDetailsOfSelectedMeeting,
    } = this.props;

    const content = meetingDetailsOfSelectedMeeting.content;

    const {
      title,
    } = meetingDetailsOfSelectedMeeting;
    // doc.save(`${title}.pdf`);

    let finalTextToDownload = '';

    content && content.forEach((contentRecord) => {
      const clientDetails = contentRecord.clientDetails;
      const name = clientDetails.name || defaultParticipantName;

      finalTextToDownload += `${name}\n\n`;

      const usersContentHistory = contentRecord.contentHistory;
      usersContentHistory && usersContentHistory.forEach((usersContentHistoryRecord) => {
        const createdAt = moment(usersContentHistoryRecord.createdAt).format('DD-MM-YYYY HH:mm:ss');
        const submission = usersContentHistoryRecord.content;
        const {
          code,
          language,
        } = submission;

        finalTextToDownload += `${language}\t\t\t\t${createdAt}\n\n`;
        finalTextToDownload += code;
        finalTextToDownload += '\n\n\n\n';
      });
      finalTextToDownload += '\n\n--------------------------------------------------------------\n\n';
    });

    // const header = '<html xmlns:o=\'urn:schemas-microsoft-com:office:office\' ' +
    // 'xmlns:w=\'urn:schemas-microsoft-com:office:word\' ' +
    // 'xmlns=\'http://www.w3.org/TR/REC-html40\'>' +
    // '<head><meta charset=\'utf-8\'><title>Editor Content</title></head><body>';
    // const footer = '</body></html>';


    var sourceHTML = finalTextToDownload;

    var source = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(sourceHTML);
    var fileDownload = document.createElement('a');
    document.body.appendChild(fileDownload);
    fileDownload.href = source;
    fileDownload.download = `${title}.doc`;
    fileDownload.click();
    document.body.removeChild(fileDownload);
  }

  render () {
    const {
      meetingDetailsOfSelectedMeeting,
    } = this.props;

    return (
      <div className='adminViewMeetingDetailsContainer'>
        <div className='adminViewMeetingDetailsTopNavBarContainer'>
          <TopNavBar />
        </div>
        <div className='adminViewMeetingDetailsMeetingDataContainer'>
          <div className='adminViewMeetingDetailsTitle'>
            {meetingDetailsOfSelectedMeeting.title}
          </div>
          <div className='adminViewMeetingDetailsTimeContainer'>
            <div className='adminViewMeetingDetailsStartTime'>
              {moment(meetingDetailsOfSelectedMeeting.startTime).format('DD-MM-YYYY HH:mm')}
            </div>
            &nbsp;-&nbsp;
            <div className='adminViewMeetingDetailsEndTime'>
              {moment(meetingDetailsOfSelectedMeeting.endTime).format('DD-MM-YYYY HH:mm')}
            </div>
          </div>
          <div className='adminViewMeetingDetailsHasMeetingStarted'>
            Meeting started&nbsp;:&nbsp;{meetingDetailsOfSelectedMeeting.hasMeetingStarted ? 'Yes' : 'No'}
          </div>
          <div className='adminViewMeetingDetailsHasMeetingEnded'>
            Meeting Ended&nbsp;:&nbsp;{meetingDetailsOfSelectedMeeting.hasMeetingEnded ? 'Yes' : 'No'}
          </div>
          {
            meetingDetailsOfSelectedMeeting.password ?
              <div className='adminViewMeetingPassword'>
                <div>
                  Password&nbsp;:&nbsp;
                </div>
                {meetingDetailsOfSelectedMeeting.password}
              </div>
              :
              null
          }
          <div className='adminViewMeetingDetailsPublicLink'>
            Public link&nbsp;:&nbsp;{meetingDetailsOfSelectedMeeting.joinLink}
          </div>
          {
            meetingDetailsOfSelectedMeeting.moderatorDetails &&
            Object.keys(meetingDetailsOfSelectedMeeting.moderatorDetails).length > 0 ?
              <div className='adminViewMeetingDetailsModeratorsContainer'>
                <div className='contentDetailsTitleContainer'>
                  Moderators:
                </div>
                <div className='adminViewMeetingDetailsModeratorDetailsContainer'>
                  {
                    (meetingDetailsOfSelectedMeeting.moderatorDetails && Object.keys(meetingDetailsOfSelectedMeeting.moderatorDetails).length > 0) ?
                      <div className='adminViewMeetingDetailsAllModerators'>
                        {
                          Object.keys(meetingDetailsOfSelectedMeeting.moderatorDetails).map((moderatorEmail) => {
                            return (
                              <div
                                key={moderatorEmail}
                                className='adminViewMeetingDetailsModerators'
                              >
                                <div className='adminViewMeetingDetailsModeratorEmail'>
                                  {moderatorEmail}
                                </div>
                                <div className='adminViewMeetingDetailsModeratorLink'>
                                  {meetingDetailsOfSelectedMeeting.moderatorDetails[moderatorEmail]}
                                </div>
                              </div>
                            );
                          })
                        }
                      </div>
                      :
                      null
                  }
                </div>
              </div>
              :
              null
          }
          {
            (meetingDetailsOfSelectedMeeting.participantDetails &&
              Object.keys(meetingDetailsOfSelectedMeeting.participantDetails).length > 0
            ) ?
              <div className='adminViewMeetingDetailsParticipantsContainer'>
                <div className='contentDetailsTitleContainer'>
                  Participants:
                </div>
                <div className='adminViewMeetingDetailsParticipantDetailsContainer'>
                  {
                    (meetingDetailsOfSelectedMeeting.participantDetails &&
                      Object.keys(meetingDetailsOfSelectedMeeting.participantDetails).length > 0
                    ) ?
                      <div className='adminViewMeetingDetailsAllParticipants'>
                        {
                          Object.keys(meetingDetailsOfSelectedMeeting.participantDetails).map((participantEmail) => {
                            return (
                              <div
                                key={participantEmail}
                                className='adminViewMeetingDetailsParticipants'
                              >
                                <div className='adminViewMeetingDetailsParticipantEmail'>
                                  {participantEmail}
                                </div>
                                <div className='adminViewMeetingDetailsParticipantLink'>
                                  {meetingDetailsOfSelectedMeeting.participantDetails[participantEmail]}
                                </div>
                              </div>
                            );
                          })
                        }
                      </div>
                      :
                      null
                  }
                </div>
              </div>
              :
              null
          }
          {
            meetingDetailsOfSelectedMeeting.content && meetingDetailsOfSelectedMeeting.content.length > 0 ?
              <div className='contentDetailsContainer'>
                <div className='contentDetailsTitleContainer'>
                  Code submissions:
                </div>
                <IconButton
                  onClick={this.downloadMeetingContentHistory}
                >
                  <CloudDownloadIcon />
                </IconButton>
                {
                  meetingDetailsOfSelectedMeeting.content.map((contentDetails) => {
                    const clientDetails = contentDetails.clientDetails;
                    const clientId = clientDetails.id;
                    const name = clientDetails.name || defaultParticipantName;

                    const contentHistory = contentDetails.contentHistory;
                    return (
                      <div
                        key={clientId}
                        className='contentDetailsParticipantContainer'
                      >
                        <div className='contentDetailsParticipantName'>
                          Participant : {name}
                        </div>
                        <div className='contentDetailsParticipantSubmissions'>
                          {
                            contentHistory.map((contentRecord, index) => {
                              const contentRecordContent = contentRecord.content;
                              return (
                                <div
                                  key={index}
                                  className='contentDetailsSubmissionRecordContainer'
                                >
                                  <div className='contentDetailsContent'>
                                    <div className='contentDetailsMetaDataContainer'>
                                      <div className='contentDetailsLanguageIndicator'>
                                        {contentRecordContent.language}
                                      </div>
                                      <div className='contentDetailsSumittedAt'>
                                        Submitted at:&nbsp;{moment(contentRecord.createdAt).format('DD-MM-YYYY HH:mm:ss')}
                                      </div>
                                    </div>
                                    <div className='contentDetailsCode'>
                                      {contentRecordContent.code}
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          }
                        </div>
                      </div>
                    );
                  })
                }
              </div>
              :
              null
          }
        </div>
      </div>
    );
  }
}

AdminSpecificMeetingView.propTypes = {
  adminToken: propTypes.string,
  getMeetingDetailsForSelectedMeeting: propTypes.func,
  match: propTypes.object,
  meetingDetailsOfSelectedMeeting: propTypes.object,
};

AdminSpecificMeetingView.defaultProps = {
  adminToken: '',
  getMeetingDetailsForSelectedMeeting: () => { },
  meetingDetailsOfSelectedMeeting: {},
};

export default AdminSpecificMeetingView;
